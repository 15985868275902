import React from "react";
import Fade from "./Fade";

function Roadmap() {
    return (
        <div id="Roadmap" style={{ backgroundImage: "url(images/body1.png)" }}>
            <h1 className="mainHeading text-center">ROADMAP</h1>
            <div className="roadmapContainer">
                <Fade>
                    <div className="roadmapInfoLeft">
                        <h1 className="roadmapHeadingLeft">20% SOLD OUT</h1> 
                        <p className="roadmapParaLeft">
                            We will open our community chats on discord 
                        </p>
                    </div>
                </Fade>
                <Fade>
                    <div className="roadmapInfo">
                        <h1 className="roadmapHeading text-uppercase">40% SOLD OUT </h1>
                        <p className="roadmapParaRight">
                            We launch our private crypto trading group and add all our holders!
                        </p>
                    </div>
                </Fade>
                <Fade>
                    <div className="roadmapInfoLeft">
                        <h1 className="roadmapHeadingLeft">60% SOLD OUT</h1>
                        <p className="roadmapParaLeft">
                            We give away $500 to 10 of our lucky holders and giveaway 
                            5 NFTs to our most supportive Dragon community members&#128009;&#128009;
                        </p>
                    </div>
                </Fade>
                <Fade>
                    <div className="roadmapInfo">
                        <h1 className="roadmapHeading">80% SOLD OUT</h1>
                        <p className="roadmapParaRight">
                        We start out post-launch marketing and introduce our collaborations !!
                        </p>
                    </div>
                </Fade>
                <Fade>
                    <div className="roadmapInfoLeft">
                        <h1 className="roadmapHeadingLeft">100% SOLD OUT</h1>
                        <p className="roadmapParaLeft"> 
                            Once we have been successfully sold out, we will do an 
                            AMA celebrating our success with our investors and Dragon holders&#128009;
                            <br></br>We will be reserving Legendary Dragons for auction, collabs and marketing purposes. Our top 25 holders will be airdropped a Legendary Dragon for free, owning that would make you an elite member of our club!
                        </p>
                    </div>
                </Fade>
            </div>
            <Fade>
                <div className="roadContainer my-3 py-5">
                    <p className="text-center">
                        We will start working on our future plans and launch our marketplace,merchandise, $FIRE Token, 
                        and we plan our first exclusive event for our EPIC Dragon Holders!
                    </p>
                </div>
            </Fade>
        </div>
        );
}

export default Roadmap;