import React from "react";
import Fade from "./Fade";

function Team() {
  return (
    <div id="Team" style={{ backgroundImage: "url(images/body2.png)" }}>
      <Fade>
        <h1 className="mainHeading text-center">TEAM</h1>
      </Fade>
      <Fade>
        <div className="container mt-5 pb-0">
          <div className="row">
            <div className="col-md-6  teamcard">
              <div className="imageContainer d-flex justify-content-center align-items-center">
                <img alt="" className="cardImage" src="images/team1.jpeg" />
              </div>
              <h1 className="featuresHeading text-center">SHAGUN</h1>
                          <p className="text-center">FOUNDER AND CEO</p>
            </div>
            <div className="col-md-6  teamcard">
              <div className="imageContainer d-flex justify-content-center align-items-center">
                <img alt="" className="cardImage" src="images/cardImage1.png" />
              </div>
                          <h1 className="featuresHeading text-center">PULKIT</h1>
                          <p className="text-uppercase text-center">Founder</p>
            </div>
          </div>
        </div>
      </Fade>
    </div>
  );
}

export default Team;
