import React from "react";
import Fade from "./Fade";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function About() {
  var settings = {
    infinite: true,
    autoplay: true,
    autoplaySpeed: 1000,
    speed: 500,
    slidesToShow: 3,
    className: "center",
    slidesToScroll: 1,
    arrows: false,
    centerMode: true,
    centerPadding: "60px",
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 720,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div id="About" style={{ backgroundImage: "url(images/body2.png)" }}>
      <Fade>
        <div className="container my-0 py-5 row mx-auto">
          <div className="col-md-6 col-sm-12 aboutInfo">
            <h1 className="text-uppercase">
              project <br />
              explanation
            </h1>
            <p>
              Ridiculous Dragons is a collection of 9999 Dragons generated randomly with bunch of artistic attributes and
              4 rarities, stored on Binance Smart Chain &#128009; &#128009;
              <br />
              <br />
              Our mission is to become the biggest project of India and introduce people to this beautiful world of
              NFTs and metaverse. &#128009; &#128009;
            </p>
          </div>
          <div className="col-md-6 col-sm-12">
                      <video src="videos/rirdiii-old.webm" alt=" " className="img-fluid aboutVid" playsinline autoPlay loop muted />
                      <img alt="" src="images/006.png" className="img-fluid mobileAbout" />
          </div>
        </div>
      </Fade>
      <div className="Carousel">
        <Slider {...settings}>
          <div className="card">
            <img src="images/006.png" alt="click here" />
          </div>
          <div className="card">
            <img src="images/008.png" alt="click here" />
          </div>
          <div className="card">
            <img src="images/009.png" alt="click here" />
          </div>
          <div className="card">
            <img src="images/0010.png" alt="click here" />
          </div>
          <div className="card">
            <img src="images/0011.png" alt="click here" />
          </div>
          <div className="card">
            <img src="images/0014.png" alt="click here" />
          </div>
        </Slider>
      </div>
      <Fade>
        <div className="container my-3 pb-5 row feature-card1 mx-auto">
          <div className="col-md-6 col-sm-12 order2 aboutInfo">
            <h1 className="text-uppercase">FIRST PRIORITY</h1>
            <p>
              Our top priority is and will always be the community. Team Ridiculous Dragons strongly believe that an
              amazing community makes for the best project. We care for our Dragon holders and will try our best to make sure
              that their investments are as profitable as possible.
            </p>
          </div>
          <div className="col-md-6 col-sm-12 d-flex order1 jusitify-content-center align-items-center">
            <img src="images/60.png" alt=" " className="img-fluid aboutVid" />
          </div>
        </div>
      </Fade>
    </div>
  );
}

export default About;
