import React from "react";
import { Accordion } from "react-bootstrap";
import Accordionitem from "./Accordionitem";
import Fade from "./Fade";

const FAQ = () => {
    const data = [
        {
            key: "0",
            heading: "How many Ridiculous Dragons will be available?",
            text: " Ridiculous Dragons is a collection of 9999 unique NFTs stored as ERC-721 on the Binance blockchain and hosted on IPFS.",
        },
        {
            key: "1",
            heading: "Where can I view my NFTs after minting?",
            text: "Once you have minted a Ridiculous Dragon, you will be able to see it in your Metamask wallet.",
        },
        {
            key: "2",
            heading: "What is the Mint Price?",
            text: "Ridiculous Dragons will be available at 0.3 BNB + gas fees.",
        },
        {
            key: "3",
            heading: "How to connect my wallet?",
            text: "After you have created your wallet you will just have to click on the button on the header of this page 'connect your wallet' and make sure you are on the Binance Network",
        },
    ];
    return (
        <div id="FAQ" className="container pt-3">
            <Fade>
                <h1 className="sectionHeading mainHeading">FAQs</h1>
                <div className="customAccordion pt-3">
                    <Accordion>
                        {data.map((item) => (
                            <Accordionitem data={item} />
                        ))}
                    </Accordion>
                </div>
            </Fade>
        </div>
    );
};

export default FAQ;