import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import FAQ from "./components/FAQ";
import Footer from "./components/Footer";
import Header from "./components/Header";
import About from "./components/About";
import Features from "./components/Features";
import Navigation from "./components/Navigation";
import Roadmap from "./components/Roadmap";
import Team from "./components/Team";
import ApeClub from "./components/ApeClub";


function App() {
  return (
    <div className="App">
       <Navigation/>
       <Header />
       <ApeClub />
       <About/>
       <Roadmap/>
       <Features/>
       <Team/>
       <FAQ/>
       <Footer />  
    </div>
  );
}

export default App;
