import React, { useState, useEffect } from "react";
import Fade from "./Fade";


function Header() {
  return (
      <div id="Header">
          <a href="https://wa.me/message/WZVK34LHPKIJA1" className="contactBtn d-flex justify-content-center align-items-center">
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-whatsapp" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#000000" fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M3 21l1.65 -3.8a9 9 0 1 1 3.4 2.9l-5.05 .9" />
                  <path d="M9 10a0.5 .5 0 0 0 1 0v-1a0.5 .5 0 0 0 -1 0v1a5 5 0 0 0 5 5h1a0.5 .5 0 0 0 0 -1h-1a0.5 .5 0 0 0 0 1" />
              </svg>
            Contact Us
        </a>
          <Fade>
              {/* <div dangerouslySetInnerHTML={{
                  __html: `
                    <video
                      loop
                      muted
                      autoplay
                      playsinline
                      src="videos/BG2.mov"
                      class="container-fluid mx-0 px-0 videoSection"
                    />,
      `           }}></div>*/}
              {/*        <div
          className=""
          dangerouslySetInnerHTML={{
            __html: `
            <video className="bg-media__video" playsinline autoplay loop muted>
            <source src="videos/BG2.webm" type="video/mp4"/>
        </video>`,
    
          }}*/}
              <div className="mobilebg">
                  <img alt="" src="images/mobilebg.png" />
              </div>
        {/* <video className="myVideo" loop autoPlay="true" muted>
          <source src="videos/bg.mp4" type="video/mp4" />
        </video> */}
              {/*        <div className="container-fluid headerBg flex-column">
          <div className="buttonGroup row">
            <a className="testing col-md-5 col-sm-12" data-text="JOIN OUR DISCORD" href="https://discord.gg/pUYCj2eqCY">
              JOIN OUR DISCORD
            </a>
            <a className="col-md-5 col-sm-12 testing" data-text="mint available soon" href="#mint">
              MINT NOW
            </a>
          </div>
              </div>*/}
      </Fade>
    </div>
  );
}

export default Header;
