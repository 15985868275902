import React from "react";
import Fade from "./Fade";

function Features() {
  return (
    <div id="Features" style={{ backgroundImage: "url(images/body1.png)" }}>
      <Fade>
        <h1 className="text-center mainHeading py-5">FEATURES</h1>
      </Fade>
      <Fade>
        <div className="container feature-card1 my-5 row mx-auto">
          <div className="col-md-6 col-sm-12 aboutInfo order2">
            <h1 className="text-uppercase">MARKETPLACE</h1>
            <p>
            The Team will be launching a Marketplace with cross-chain tokens like Eth, Matic, BNB, etc. Team will also launch its own token $FIRE which can be used on our marketplace to trade NFTs.
            </p>
          </div>
          <div className="col-md-6 col-sm-12 d-flex order1 jusitify-content-center align-items-center">
            <img src="images/cardImage1.png" alt=" " className="img-fluid aboutVid2" />
          </div>
        </div>
      </Fade>
      <Fade>
        <div className="container my-5 row mx-auto">
          <div className="col-md-6 col-sm-12 d-flex jusitify-content-center align-items-center">
            <img src="images/cardImage2.png" alt=" " className="img-fluid aboutVid2" />
          </div>
          <div className="col-md-6 col-sm-12 aboutInfo">
            <h1 className="text-uppercase">$FIRE STAKING</h1>
            <p>
            We will be launching our own token on Binance Smart Chain called $FIRE. 1% Staking rewards for 10 or less NFT holders.
            Also 3% from the revenue generated from trade made on secondary marketplace (To be announced soon) will be distributed among 10+ NFT holders and Legendary Dragon holders!
            </p>
          </div>
        </div>
      </Fade>
      <Fade>
        <div className="container my-5 row feature-card1 mx-auto">
          <div className="col-md-6 col-sm-12 order2 aboutInfo">
            <h1 className="text-uppercase">Premium Crypto Trading Group</h1>
            <p>
              All Dragon holders &#128009; will get lifetime access to our exclusive crypto trading group, where our
              holders will get premium trading calls, market-related analysis & updates from experts who have been
              trading since 2012. Normally we charge $65 for 6 months of this service.
            </p>
          </div>
          <div className="col-md-6 col-sm-12 d-flex order1 jusitify-content-center align-items-center">
            <img src="images/cardImage4.png" alt=" " className="img-fluid aboutVid2 h-100" />
          </div>
        </div>
      </Fade>
      <Fade>
        <div className="container my-5  row mx-auto">
          <div className="col-md-6 col-sm-12 d-flex jusitify-content-center align-items-center">
            <img src="images/37.png" alt=" " className="img-fluid aboutVid2" />
          </div>
          <div className="col-md-6 col-sm-12 aboutInfo">
            <h1 className="text-uppercase">merch</h1>
            <p>
              After selling out we will be releasing our Merch Line in collaboration with one of the best designers so
              Ridiculous Dragon holders can proudly represent the group they belong to. People who hold more than 1
              Dragon will get our merch for FREE!
            </p>
          </div>
        </div>
      </Fade>
      <Fade>
        <div className="container my-5 row feature-card1 mx-auto">
          <div className="col-md-6 col-sm-12 order2 aboutInfo">
            <h1 className="text-uppercase">VIP Status</h1>
            <p>
              Holders with 3 or more Dragon will also get VIP Status on our Discord server and enjoy exclusive
              giveaways for multiple holders.
            </p>
          </div>
          <div className="col-md-6 col-sm-12 d-flex order1 jusitify-content-center align-items-center">
            <img src="images/24.png" alt=" " className="img-fluid aboutVid2" />
          </div>
        </div>
      </Fade>
      <Fade>
        <div className="container my-5  row mx-auto">
          <div className="col-md-6 col-sm-12 d-flex jusitify-content-center align-items-center">
            <img src="images/41.png" alt=" " className="img-fluid aboutVid2" />
          </div>
          <div className="col-md-6 col-sm-12 aboutInfo">
            <h1 className="text-uppercase">Dragon Offer&#128009;&#128009; </h1>
            <p>All our Dragon holders will get 20% off on our next collection!</p>
          </div>
        </div>
      </Fade>
      <Fade>
        <div className="container my-5 py-0 feature-card1 row mx-auto">
          <div className="col-md-6 col-sm-12 order2 aboutInfo">
            <h1 className="text-uppercase">EXCLUSIVE EVENTS</h1>
            <p>
              To celebrate our project's success, we will be organising exclusive parties and events for Ridiculous
              Dragon holders, location will be decided by the community!
            </p>
          </div>
          <div className="col-md-6 col-sm-12 order1 d-flex jusitify-content-center align-items-center">
            <img src="images/74.png" alt=" " className="img-fluid aboutVid2" />
          </div>
        </div>
      </Fade>
    </div>
  );
}

export default Features;
