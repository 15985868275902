import React from "react";

const Footer = () => {
    return (
        <div className="footer">
            <p>&#169; Copyright Ridiculous Dragons 2022</p>
        </div>
    );
};

export default Footer;