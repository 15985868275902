import React from 'react';

export default class Navigation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            display: "none",
        };
        this.ToggleButton = this.ToggleButton.bind(this);
    }

    ToggleButton() {
        if (this.state.display == "none") {
            this.setState({
                display: "block",
            });
        } else {
            this.setState({
                display: "none",
            });
        }

    }

    render() {
        let styles = {
            display: "none",
        };
        if (this.state.display == "none") {
            styles = {
                display: "none",
            }
        }
        if (this.state.display == "block") {
            styles = {
                display: "block",
            }
        }
        return (
            <div id="Navigation">
                <div className="container-fluid navBg">
                    <nav className="navbar navbar-expand-lg">
                        <div className="container-fluid">
                            <a className="navbar-brand logo" href="#"><img className="img " src="images/logo2.png" /></a>
                            <button class="navbar-toggler custom-toggler" type="button" onClick={this.ToggleButton}>
                                <span class="navbar-toggler-icon"></span>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarSupportedContent" style={ styles }>
                                <ul className="navbar-nav mx-auto d-flex align-items-end">
                                    <li className="nav-link">
                                        <a href="#Roadmap" className='itemHeading ms-auto'>RoadMap</a>
                                    </li>
                                    <li className="nav-link">
                                        <a href="#Features" className="itemHeading ms-auto">Features</a>
                                    </li>
                                    <li className="nav-link">
                                        <a href="#Team" className="itemHeading ms-auto">Team</a>
                                    </li>
                                    <li className="nav-link">
                                        <a href="#mint" className="itemHeading ms-auto text-nowrap">Mint Now</a>
                                    </li>
                                </ul>
                                <li className="nav-link socialLink">
                                    <a href="https://discord.gg/ridiculousdragons" className="socialIcon">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-discord" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#ffffff" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <circle cx="9" cy="12" r="1" />
                                            <circle cx="15" cy="12" r="1" />
                                            <path d="M7.5 7.5c3.5 -1 5.5 -1 9 0" />
                                            <path d="M7 16.5c3.5 1 6.5 1 10 0" />
                                            <path d="M15.5 17c0 1 1.5 3 2 3c1.5 0 2.833 -1.667 3.5 -3c.667 -1.667 .5 -5.833 -1.5 -11.5c-1.457 -1.015 -3 -1.34 -4.5 -1.5l-1 2.5" />
                                            <path d="M8.5 17c0 1 -1.356 3 -1.832 3c-1.429 0 -2.698 -1.667 -3.333 -3c-.635 -1.667 -.476 -5.833 1.428 -11.5c1.388 -1.015 2.782 -1.34 4.237 -1.5l1 2.5" />
                                        </svg>
                                    </a>
                                    <a href="https://twitter.com/ridiculousverse?s=11" className="socialIcon">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-twitter" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#ffffff" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <path d="M22 4.01c-1 .49 -1.98 .689 -3 .99c-1.121 -1.265 -2.783 -1.335 -4.38 -.737s-2.643 2.06 -2.62 3.737v1c-3.245 .083 -6.135 -1.395 -8 -4c0 0 -4.182 7.433 4 11c-1.872 1.247 -3.739 2.088 -6 2c3.308 1.803 6.913 2.423 10.034 1.517c3.58 -1.04 6.522 -3.723 7.651 -7.742a13.84 13.84 0 0 0 .497 -3.753c-.002 -.249 1.51 -2.772 1.818 -4.013z" />
                                        </svg>
                                    </a>
                                    <a href="https://instagram.com/ridiculousdragons" className="socialIcon">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-instagram" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#ffffff" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <rect x="4" y="4" width="16" height="16" rx="4" />
                                            <circle cx="12" cy="12" r="3" />
                                            <line x1="16.5" y1="7.5" x2="16.5" y2="7.501" />
                                        </svg>
                                    </a>
                                </li>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        );
    }
}
